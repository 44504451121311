export const DATA_PROVIDER_ROLE = 'DATA_PROVIDER_ROLE';
export const NON_UPDATED_PASSWORD = 'non-updated-password';

export enum AuthMethodCode {
	API_KEY = 'API_KEY',
	CLIENT_ID_API_KEY = 'CLIENT_ID_API_KEY',
	EXTERNAL_ACCOUNT_ID = 'EXTERNAL_ACCOUNT_ID',
	LOGIN_PASSWORD = 'LOGIN_PASSWORD',
	OAUTH_TOKEN = 'OAUTH_TOKEN',
}
