/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in SecurityPackage
 * @author vyasinskiy Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * Request from end user for access to one or more application for new or existing tenant
  */
  w.AccessRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "ACCESS_REQUEST";
    this.CopySupportingElement = false;
    this.Applicant = null;
    this.PromoCode = "";
    this.Applications = [];
    this.GatekeeperUserLogin = "";
    this.TenantProviderID = -1;
    this.TariffCode = "";
    this.BusinessServiceID = -1;
    this.ChangePasswordAfterRegistration = false;
    this.TenantID = -1;
    this.ClientIPAddress = "";
    this.ClientApplicationCode = "";
    this.RegistrationMethodCode = "";
    this.YmClientID = "";
    this.GaClientID = "";
    this.RegistrationSourceID = -1;
    this.AuthMethodCode = "";
    this.RequestURL = "";
    this.ClientPlatformCode = "";
    this.DealType = -1;
    this.SecuritySessionID = -1;
  };
  /***
  * 
  */
  w.AccessRequestResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "ACCESS_REQUEST_RESPONSE";
    this.CopySupportingElement = false;
    this.ActivationCode = "";
    this.RequestAccepted = false;
    this.Message = "";
  };
  /***
  * 
  */
  w.AccessTokenValidationResult = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "ACCESS_TOKEN_VALIDATION_RESULT";
    this.CopySupportingElement = false;
    this.TicketUID = "";
    this.Valid = false;
    this.Status = "";
    this.Message = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.ActivationResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "ACTIVATION_RESPONSE";
    this.CopySupportingElement = false;
    this.Message = "";
    this.ActivationSuccess = false;
    this.UserPassword = "";
    this.UserLogin = "";
  };
  /***
  * 
  */
  w.APITenantProviderAccount = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "APITENANT_PROVIDER_ACCOUNT";
    this.CopySupportingElement = false;
    this.OrganizationUID = "";
    this.OrganizationLegalName = "";
    this.OrganizationTaxCode = "";
    this.ContactPersonName = "";
    this.ContactEMail = "";
    this.ContactPhoneNumber = "";
  };
  /***
  * Common information about applicant that request for access to application
  */
  w.Applicant = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "APPLICANT";
    this.CopySupportingElement = false;
    this.EMail = "";
    this.OrganizationName = "";
    this.Name = "";
    this.PhoneNumber = "";
    this.Comment = "";
    this.WebSiteURL = "";
    this.Password = "";
  };
  /***
  * Options of requesting access to business application
  */
  w.ApplicationRequestOption = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "APPLICATION_REQUEST_OPTION";
    this.CopySupportingElement = false;
    this.BusinessApplicationGUID = "";
  };
  /***
  * 
  */
  w.AuthenticationServiceType = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "AUTHENTICATION_SERVICE_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.SystemName = "";
    this.Name = "";
  };
  /***
  * 
  */
  w.AuthenticationSession = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "AUTHENTICATION_SESSION";
    this.CopySupportingElement = false;
    this.AccessToken = "";
    this.ID = -1;
    this.AuthCode = "";
    this.ClientID = "";
    this.ExternalOrgGUID = "";
    this.ExternalUserGUID = "";
    this.IdentityToken = "";
    this.RefreshToken = "";
    this.Status = "";
    this.SubsystemInstance = null;
    this.TicketUID = "";
    this.UserID = -1;
    this.UserInfoINN = "";
    this.UserInfoEmail = "";
    this.UserInfoUserName = "";
    this.SessionStartedDateTime = null;
    this.SessionFinishedDateTime = null;
    this.GUID = "";
    this.TenantID = -1;
    this.BusinessService = null;
    this.Message = "";
    this.UserInfoOrgName = "";
    this.ClientIPAddress = "";
    this.AuthorizationMode = false;
    this.NewTenant = false;
  };
  /***
  * 
  */
  w.AuthenticationSessionRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "AUTHENTICATION_SESSION_REQUEST";
    this.CopySupportingElement = false;
    this.AuthenticationSession = null;
    this.RequestURL = "";
    this.RequestData = "";
    this.ResponseData = "";
    this.ResponseCode = -1;
    this.RequestType = null;
    this.Status = "";
  };
  /***
  * 
  */
  w.AuthMethod = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "AUTH_METHOD";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.ClientApplication = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "CLIENT_APPLICATION";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.ClientPlatform = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "CLIENT_PLATFORM";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * Client session context object storing main data for client session and 
  passed to client side for use in web application
  */
  w.ClientSessionContext = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "CLIENT_SESSION_CONTEXT";
    this.CopySupportingElement = false;
    this.SecurityTicket = null;
    this.CurrentPageflow = "";
    this.CurrentPageflowNode = "";
    this.UserRights = [];
    this.AvailableComponents = [];
    this.WebServicesRootPath = "";
    this.ContextParams = {};
    this.SessionUser = null;
    this.SessionTenant = null;
    this.SessionApplication = null;
    this.SessionTimeoutSeconds = -1;
  };
  /***
  * 
  */
  w.CredentialsBillet = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "CREDENTIALS_BILLET";
    this.CopySupportingElement = false;
    this.Login = "";
    this.Password = "";
    this.ID = -1;
  };
  /***
  * 
  */
  w.ExternalOpenIDAccessToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "EXTERNAL_OPEN_IDACCESS_TOKEN";
    this.CopySupportingElement = false;
    this.AccessToken = "";
    this.RefreshToken = "";
    this.ExpireSeconds = -1;
    this.TokenClientID = "";
    this.IdentityToken = "";
  };
  /***
  * 
  */
  w.ExternalSystemAccount = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "EXTERNAL_SYSTEM_ACCOUNT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Validated = false;
    this.ValidTo = null;
    this.ExternalSSInstanceID = -1;
    this.ExternalSSInstanceName = "";
    this.AccessToken = "";
    this.GUID = "";
    this.AdminUser = "";
    this.AdminPassword = "";
    this.RefreshToken = "";
    this.Name = "";
    this.TokenClientID = "";
    this.IdentityToken = "";
    this.BusinessService = null;
    this.TaxCode = "";
    this.AdditionalTaxCode = "";
    this.ApiKey = "";
  };
  /***
  * 
  */
  w.ExternalSystemAuthRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "EXTERNAL_SYSTEM_AUTH_REQUEST";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.RequestDateTime = null;
    this.GUID = "";
    this.ExternalSystemAccount = null;
    this.Status = null;
    this.SessionUID = "";
    this.ExternalSSInstance = null;
    this.AuthCallbackURL = "";
  };
  /***
  * 
  */
  w.ExternalSystemSecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "EXTERNAL_SYSTEM_SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.ExternalUserGUID = "";
    this.Nonce = "";
    this.ExternalOrganizationGUID = "";
    this.TokenClientID = "";
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * Security token containing External UID of tenant that want to get access to the system. 
  It must be passed to gatekeeper service in order to perform authentication
  */
  w.GatekeeperSecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "GATEKEEPER_SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.TenantExternalUID = "";
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * 
  */
  w.InternalSecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "INTERNAL_SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.Checksum = "";
    this.Login = "";
    this.Password = "";
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * 
  */
  w.Lead = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "LEAD";
    this.CopySupportingElement = false;
    this.CompanyName = "";
    this.CompanyTaxCode = "";
    this.ContactName = "";
    this.ContactPhone = "";
    this.ContactEmail = "";
    this.Comment = "";
    this.CreationDate = null;
    this.ID = -1;
    this.Status = null;
    this.RegistrationDate = null;
    this.LeadTenantID = -1;
    this.PartnerTenantID = -1;
  };
  /***
  * Security token subtype that allows to authenticate to a subsystem
   using login-password pair
  */
  w.LoginPasswordSecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "LOGIN_PASSWORD_SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.Login = "";
    this.Password = "";
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * 
  */
  w.OAuthAccessToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "OAUTH_ACCESS_TOKEN";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Value = "";
    this.IssueDateTime = null;
    this.ExpireDateTime = null;
    this.Resource = "";
    this.Revoked = false;
    this.RevokeDateTime = null;
  };
  /***
  * 
  */
  w.OneTimePasswordSecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "ONE_TIME_PASSWORD_SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.Login = "";
    this.Password = "";
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * 
  */
  w.OtpAuthCodeResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "OTP_AUTH_CODE_RESPONSE";
    this.CopySupportingElement = false;
    this.AuthCode = "";
    this.Success = false;
    this.ErrorMessage = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.OtpResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "OTP_RESPONSE";
    this.CopySupportingElement = false;
    this.Success = false;
    this.ErrorMessage = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.OtpSendResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "OTP_SEND_RESPONSE";
    this.CopySupportingElement = false;
    this.Timeout = -1;
    this.AttemptCount = -1;
    this.Success = false;
    this.ErrorMessage = "";
    this.ErrorCode = "";
  };
  /***
  * 
  */
  w.PartnerStatus = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "PARTNER_STATUS";
    this.CopySupportingElement = false;
    this.RegistrationsCount = -1;
    this.SalesCount = -1;
    this.TransitionsCount = -1;
  };
  /***
  * 
  */
  w.PartnerStatusRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "PARTNER_STATUS_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
  };
  /***
  * 
  */
  w.PromoCode = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "PROMO_CODE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Code = "";
    this.Valid = false;
    this.Message = "";
  };
  /***
  * Информация о переходе лида на страницу регистрации
  */
  w.RegisterLeadTransitionRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "REGISTER_LEAD_TRANSITION_REQUEST";
    this.CopySupportingElement = false;
    this.PartnerTenantID = -1;
  };
  /***
  * 
  */
  w.RegistrationMethod = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "REGISTRATION_METHOD";
    this.CopySupportingElement = false;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * Defines an agent of security system.
  Agent could be a user or external system.
  Agent is identified by {@link SecurityBoard} during authentication 
  procedure on the basis of given {@link SecurityToken}
  */
  w.SecurityAgent = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_AGENT";
    this.CopySupportingElement = false;
    this.SystemName = "";
    this.Name = "";
    this.StorageID = -1;
    this.UniqueID = "";
    this.Password = "";
    this.Tenants = [];
    this.ActiveTenant = null;
    this.AvailableBusinessApplications = [];
    this.ActiveBusinessApplication = null;
    this.LocaleCode = "";
    this.SubsystemInstanceTokens = [];
    this.SecurityRights = [];
    this.LastLoginDateTime = null;
    this.LoginEventCount = -1;
    this.FirstLoginDateTime = null;
    this.ContactPhoneNumber = "";
    this.ContactEMail = "";
    this.ChangePwdNextLogin = false;
    this.TechSupport = false;
    this.AuthenticationServiceType = null;
    this.APIUser = false;
    this.Blocked = false;
    this.PhoneNumberConfirmed = false;
  };
  /***
  * 
  */
  w.SecurityAgentTicket = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_AGENT_TICKET";
    this.CopySupportingElement = false;
    this.SecurityAgent = null;
    this.TicketUID = "";
  };
  /***
  * Defines a possibility for user to perform some operation in system 
  according to security rules
  */
  w.SecurityRight = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_RIGHT";
    this.CopySupportingElement = false;
    this.Module = null;
    this.ContainerPackage = null;
    this.SystemName = "";
    this.Brief = "";
    this.Name = "";
    this.Description = "";
    this.GUID = "";
    this.setModule = function(source) {
      var ref = new TypedReference();
      ref.initFromSource(source);
      this.Module = ref;
    };
    this.setContainerPackage = function(source) {
      var ref = new TypedReference();
      ref.initFromSource(source);
      this.ContainerPackage = ref;
    };
  };
  /***
  * 
  */
  w.SecurityRole = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_ROLE";
    this.CopySupportingElement = false;
    this.Module = null;
    this.ContainerPackage = null;
    this.Rights = [];
    this.SystemName = "";
    this.Brief = "";
    this.Name = "";
    this.Description = "";
    this.GUID = "";
    this.setModule = function(source) {
      var ref = new TypedReference();
      ref.initFromSource(source);
      this.Module = ref;
    };
    this.setContainerPackage = function(source) {
      var ref = new TypedReference();
      ref.initFromSource(source);
      this.ContainerPackage = ref;
    };
    this.setRights = function(source) {
      var ref = new TypedReference();
      ref.initFromSource(source);
      this.Rights = ref;
    };
  };
  /***
  * 
  */
  w.SecuritySession = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_SESSION";
    this.CopySupportingElement = false;
    this.TicketUID = "";
    this.Active = false;
    this.StartDateTime = null;
    this.LastActionDateTime = null;
    this.TenantName = "";
    this.UserName = "";
    this.UISession = false;
    this.ID = -1;
    this.BusinessService = null;
    this.ClientIPAddress = "";
  };
  /***
  * 
  */
  w.SecuritySessionFilter = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_SESSION_FILTER";
    this.CopySupportingElement = false;
    this.OnlyActive = false;
    this.UISession = false;
    this.TenantIDList = "";
    this.ActionIntervalStart = null;
    this.ActionIntervalEnd = null;
    this.TenantGroupID = -1;
  };
  /***
  * Defines unique key, identifying user session in the system. 
  It is obtained after successful authentication and allows user to perform operation with objects in system 
  */
  w.SecurityTicket = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_TICKET";
    this.CopySupportingElement = false;
    this.TicketUID = "";
    this.ClientIPAddress = "";
    this.TicketIssueTimeInMillis = -1;
    this.LastValidationTimeInMillis = -1;
    this.Domain = "";
    this.SessionID = -1;
  };
  /***
  * Defines an object containing necessary authentication info that needs to be provided 
  to security subsystem to get security ticket
  */
  w.SecurityToken = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SECURITY_TOKEN";
    this.CopySupportingElement = false;
    this.ClientIPAddress = "";
    this.SubsystemInstance = null;
    this.LocaleCode = "";
    this.Domain = "";
    this.BusinessService = null;
    this.ClientApplication = null;
    this.ClientPlatform = null;
    this.AuthMethod = null;
  };
  /***
  * 
  */
  w.ServiceCallSessionAction = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SERVICE_CALL_SESSION_ACTION";
    this.CopySupportingElement = false;
    this.ServiceURL = "";
    this.ServiceMethod = "";
    this.TicketUID = "";
    this.SessionID = -1;
    this.ActionDateTime = null;
  };
  /***
  * 
  */
  w.SessionAction = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SESSION_ACTION";
    this.CopySupportingElement = false;
    this.TicketUID = "";
    this.SessionID = -1;
    this.ActionDateTime = null;
  };
  /***
  * 
  */
  w.SessionVariable = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "SESSION_VARIABLE";
    this.CopySupportingElement = false;
    this.Name = "";
    this.Value = "";
  };
  /***
  * Defines Tenant
  */
  w.Tenant = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT";
    this.CopySupportingElement = false;
    this.StorageID = -1;
    this.Name = "";
    this.AvailableSubsystemInstances = [];
    this.Industries = [];
    this.AvailableComponents = [];
    this.ExternalUID = "";
    this.LegalData = null;
    this.ContactPhoneNumber = "";
    this.ContactEMail = "";
    this.ContactPersonName = "";
    this.Provider = null;
    this.ContactValidationDateTime = null;
  };
  /***
  * 
  */
  w.TenantBrief = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_BRIEF";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
  };
  /***
  * 
  */
  w.TenantCreationRequest = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_CREATION_REQUEST";
    this.CopySupportingElement = false;
    this.TenantName = "";
    this.TenantExternalUID = "";
    this.AccessedSSInstances = [];
    this.AccessedAppComponents = [];
    this.GatekeeperUserLogin = "";
    this.GatekeeperUserLocalID = -1;
    this.CredentialsBilletID = -1;
    this.UpdateCredentials = false;
    this.BillingGroupID = -1;
    this.GrantedSecurityRoles = [];
    this.TenantProviderID = -1;
  };
  /***
  * 
  */
  w.TenantCreationResponse = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_CREATION_RESPONSE";
    this.CopySupportingElement = false;
    this.Tenant = null;
    this.Request = null;
  };
  /***
  * 
  */
  w.TenantGroup = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_GROUP";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
  };
  /***
  * 
  */
  w.TenantLegalData = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_LEGAL_DATA";
    this.CopySupportingElement = false;
    this.LegalName = "";
    this.LegalAddress = "";
    this.BankName = "";
    this.BankBIC = "";
    this.BankCorrAccNumber = "";
    this.SettlementAccountNumber = "";
    this.StateRegNumber = "";
    this.TaxReferenceNumber = "";
    this.TaxRegReasonCode = "";
  };
  /***
  * 
  */
  w.TenantProvider = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_PROVIDER";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Logo = "";
    this.SystemName = "";
    this.BrandTheme = "";
  };
  /***
  * 
  */
  w.TenantQueryFilter = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "TENANT_QUERY_FILTER";
    this.CopySupportingElement = false;
    this.TenantID = -1;
    this.TenantName = "";
    this.TenantContactEmail = "";
    this.TenantContactPerson = "";
    this.TenantContactPhoneNumber = "";
    this.TenantTaxReferenceNumber = "";
  };
  /***
  * 
  */
  w.UISessionAction = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "UISESSION_ACTION";
    this.CopySupportingElement = false;
    this.Pageflow = "";
    this.UIForm = "";
    this.TicketUID = "";
    this.SessionID = -1;
    this.ActionDateTime = null;
  };
  /***
  * 
  */
  w.UserBrief = function() {
    this.PACKAGE = "SECURITY";
    this.CLASSIFIER = "USER_BRIEF";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Login = "";
  };
   
   
  
  /***
   * Client API to AuthenticationServiceWS web service
   */
  w.AuthenticationServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "AuthenticationServiceWSImpl";
  };
  
  /***
   * Authenticates external system agent by given {@link SecurityToken}.
  If authentication is successful {@link SecurityTicket} is generated that allows user 
  to perform operations in system
  
   * @param token {@link SecurityToken} containing necessary authentication info
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.authenticate = function(token, callbackHandler) {
    var parameterNames = ["token"];
    var tokenJSON = JSON.stringify(token);
    var parameterValues = [tokenJSON];
    var soapMessage = buildSOAPMessage("authenticate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Validates {@link SecurityTicket} with given identifier at the current moment of time.
  After validation services updates ticket last activity timestamp.
  Result of ticket validation, that could be one of the following
  &amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;lt;li&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;gt; TICKET_VALID - ticket is valid for user
  &amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;lt;li&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;gt; TICKET_EXPIRED - ticket is expired
  &amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;lt;li&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;gt; TICKET_INVALID_IP - IP of ticket presenter differs from initial bearer of ticket 
  &amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;lt;li&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;gt; TICKET_UNKNOWN - ticket is unknown
  
   * @param ticketUID Unique ID of {@link SecurityTicket}
   * @param clientIPAddress IP address of a client request. 
  If IP Address of client changed since ticket issue moment than ticket is considered invalid
   * @param domain [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.validateTicket = function(ticketUID, clientIPAddress, domain, callbackHandler) {
    var parameterNames = ["ticketUID", "clientIPAddress", "domain"];
    var parameterValues = [ticketUID, clientIPAddress, domain];
    var soapMessage = buildSOAPMessage("validateTicket", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Defines getAgentByTicket operation
   * @param ticketUID 
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.getAgentByTicket = function(ticketUID, callbackHandler) {
    var parameterNames = ["ticketUID"];
    var parameterValues = [ticketUID];
    var soapMessage = buildSOAPMessage("getAgentByTicket", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Changes locale of an agent for current session!DEFAULT]
   * @param localeCode [DEFAULT!Code of locale  in which user wants to get results!DEFAULT]
   * @param ticketUID [DEFAULT!UID of a ticket assigned to an agent for session!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.changeAgentSessionLocale = function(localeCode, ticketUID, callbackHandler) {
    var parameterNames = ["localeCode", "ticketUID"];
    var parameterValues = [localeCode, ticketUID];
    var soapMessage = buildSOAPMessage("changeAgentSessionLocale", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param action [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.registerSessionAction = function(action, callbackHandler) {
    var parameterNames = ["action"];
    var actionJSON = JSON.stringify(action);
    var parameterValues = [actionJSON];
    var soapMessage = buildSOAPMessage("registerSessionAction", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invitationCode [DEFAULT!Invitation code that a user must provide to identify his authenticity!DEFAULT]
   * @param tenantChecksum [DEFAULT!Checksum of tenant that issued invitation code!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.checkInvitation = function(invitationCode, tenantChecksum, callbackHandler) {
    var parameterNames = ["invitationCode", "tenantChecksum"];
    var parameterValues = [invitationCode, tenantChecksum];
    var soapMessage = buildSOAPMessage("checkInvitation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Checks invitation and if it is correct adds invited user to security DB
   and creates new session for user!DEFAULT]
   * @param invitationCode [DEFAULT!!DEFAULT]
   * @param tenantChecksum [DEFAULT!!DEFAULT]
   * @param userEmail [DEFAULT!!DEFAULT]
   * @param userPassword [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.registerInvitedUser = function(invitationCode, tenantChecksum, userEmail, userPassword, callbackHandler) {
    var parameterNames = ["invitationCode", "tenantChecksum", "userEmail", "userPassword"];
    var parameterValues = [invitationCode, tenantChecksum, userEmail, userPassword];
    var soapMessage = buildSOAPMessage("registerInvitedUser", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Checks if tenant has non activated invitations!DEFAULT]
   * @param tenantChecksum [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.tenantHasInvitations = function(tenantChecksum, callbackHandler) {
    var parameterNames = ["tenantChecksum"];
    var parameterValues = [tenantChecksum];
    var soapMessage = buildSOAPMessage("tenantHasInvitations", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Checks password recovery code and returns true success message if code is valid.
  Otherwise returns false and message describing invalid status reason!DEFAULT]
   * @param code [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.checkPasswordRecoveryCode = function(code, callbackHandler) {
    var parameterNames = ["code"];
    var parameterValues = [code];
    var soapMessage = buildSOAPMessage("checkPasswordRecoveryCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param code [DEFAULT!!DEFAULT]
   * @param newPassword [DEFAULT!!DEFAULT]
   * @param login [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.recoverPassword = function(code, newPassword, login, callbackHandler) {
    var parameterNames = ["code", "newPassword", "login"];
    var parameterValues = [code, newPassword, login];
    var soapMessage = buildSOAPMessage("recoverPassword", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param login [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.requestPasswordRecovery = function(login, callbackHandler) {
    var parameterNames = ["login"];
    var parameterValues = [login];
    var soapMessage = buildSOAPMessage("requestPasswordRecovery", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalToken [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.checkExternalTokenExists = function(externalToken, callbackHandler) {
    var parameterNames = ["externalToken"];
    var externalTokenJSON = JSON.stringify(externalToken);
    var parameterValues = [externalTokenJSON];
    var soapMessage = buildSOAPMessage("checkExternalTokenExists", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ticketUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.logout = function(ticketUID, callbackHandler) {
    var parameterNames = ["ticketUID"];
    var parameterValues = [ticketUID];
    var soapMessage = buildSOAPMessage("logout", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param authSession [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.createAuthenticationSession = function(authSession, callbackHandler) {
    var parameterNames = ["authSession"];
    var authSessionJSON = JSON.stringify(authSession);
    var parameterValues = [authSessionJSON];
    var soapMessage = buildSOAPMessage("createAuthenticationSession", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param authSession [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.updateAuthenticationSession = function(authSession, callbackHandler) {
    var parameterNames = ["authSession"];
    var authSessionJSON = JSON.stringify(authSession);
    var parameterValues = [authSessionJSON];
    var soapMessage = buildSOAPMessage("updateAuthenticationSession", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param authSession [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.closeAuthenticationSession = function(authSession, callbackHandler) {
    var parameterNames = ["authSession"];
    var authSessionJSON = JSON.stringify(authSession);
    var parameterValues = [authSessionJSON];
    var soapMessage = buildSOAPMessage("closeAuthenticationSession", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sessionRequest [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.addAuthenticationSessionRequest = function(sessionRequest, callbackHandler) {
    var parameterNames = ["sessionRequest"];
    var sessionRequestJSON = JSON.stringify(sessionRequest);
    var parameterValues = [sessionRequestJSON];
    var soapMessage = buildSOAPMessage("addAuthenticationSessionRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sessionGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.getAuthenticationSessionByGUID = function(sessionGUID, callbackHandler) {
    var parameterNames = ["sessionGUID"];
    var parameterValues = [sessionGUID];
    var soapMessage = buildSOAPMessage("getAuthenticationSessionByGUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthenticationServiceClient.prototype.generateCarrotQuestAuthHash = function(userID, callbackHandler) {
    var parameterNames = ["userID"];
    var parameterValues = [userID];
    var soapMessage = buildSOAPMessage("generateCarrotQuestAuthHash", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Client API to AuthorizationServiceWS web service
   */
  w.AuthorizationServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "AuthorizationServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityRight [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.publishSecurityRight = function(securityRight, callbackHandler) {
    var parameterNames = ["securityRight"];
    var securityRightJSON = JSON.stringify(securityRight);
    var parameterValues = [securityRightJSON];
    var soapMessage = buildSOAPMessage("publishSecurityRight", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityRole [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.publishSecurityRole = function(securityRole, callbackHandler) {
    var parameterNames = ["securityRole"];
    var securityRoleJSON = JSON.stringify(securityRole);
    var parameterValues = [securityRoleJSON];
    var soapMessage = buildSOAPMessage("publishSecurityRole", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityRightGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getSecurityRightByGUID = function(securityRightGUID, callbackHandler) {
    var parameterNames = ["securityRightGUID"];
    var parameterValues = [securityRightGUID];
    var soapMessage = buildSOAPMessage("getSecurityRightByGUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityRoleGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getSecurityRoleByGUID = function(securityRoleGUID, callbackHandler) {
    var parameterNames = ["securityRoleGUID"];
    var parameterValues = [securityRoleGUID];
    var soapMessage = buildSOAPMessage("getSecurityRoleByGUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityAgentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getSecurityAgentRoles = function(securityAgentID, callbackHandler) {
    var parameterNames = ["securityAgentID"];
    var parameterValues = [securityAgentID];
    var soapMessage = buildSOAPMessage("getSecurityAgentRoles", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityAgentID [DEFAULT!!DEFAULT]
   * @param securityRoleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.addRoleToSecurityAgent = function(securityAgentID, securityRoleID, callbackHandler) {
    var parameterNames = ["securityAgentID", "securityRoleID"];
    var parameterValues = [securityAgentID, securityRoleID];
    var soapMessage = buildSOAPMessage("addRoleToSecurityAgent", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param securityAgentID [DEFAULT!!DEFAULT]
   * @param securityRoleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.removeRoleFromSecurityAgent = function(securityAgentID, securityRoleID, callbackHandler) {
    var parameterNames = ["securityAgentID", "securityRoleID"];
    var parameterValues = [securityAgentID, securityRoleID];
    var soapMessage = buildSOAPMessage("removeRoleFromSecurityAgent", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getAvailableRoles = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("getAvailableRoles", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rightGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.currentUserHasRight = function(rightGUID, callbackHandler) {
    var parameterNames = ["rightGUID"];
    var parameterValues = [rightGUID];
    var soapMessage = buildSOAPMessage("currentUserHasRight", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.generateAccessToken = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("generateAccessToken", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tokenValue [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.revokeAccessToken = function(tokenValue, callbackHandler) {
    var parameterNames = ["tokenValue"];
    var parameterValues = [tokenValue];
    var soapMessage = buildSOAPMessage("revokeAccessToken", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tokenValue [DEFAULT!!DEFAULT]
   * @param domain [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.validateAccessToken = function(tokenValue, domain, callbackHandler) {
    var parameterNames = ["tokenValue", "domain"];
    var parameterValues = [tokenValue, domain];
    var soapMessage = buildSOAPMessage("validateAccessToken", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param includeRevoked [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getAccessTokens = function(includeRevoked, callbackHandler) {
    var parameterNames = ["includeRevoked"];
    var parameterValues = [includeRevoked];
    var soapMessage = buildSOAPMessage("getAccessTokens", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param rightsList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.changeUserRights = function(userID, rightsList, callbackHandler) {
    var parameterNames = ["userID", "rightsList"];
    var rightsListJSON = JSON.stringify(rightsList);
    var parameterValues = [userID, rightsListJSON];
    var soapMessage = buildSOAPMessage("changeUserRights", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getAvailableRights = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getAvailableRights", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.AuthorizationServiceClient.prototype.getUserRights = function(userID, callbackHandler) {
    var parameterNames = ["userID"];
    var parameterValues = [userID];
    var soapMessage = buildSOAPMessage("getUserRights", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Client API to DirectoryServiceWS web service
   */
  w.DirectoryServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "DirectoryServiceWSImpl";
  };
  
  /***
   * [DEFAULT!Changes existing user password by new one checking that previous password was provided correctly !DEFAULT]
   * @param login [DEFAULT!Login of user whose password is changed!DEFAULT]
   * @param oldPassword [DEFAULT!Old password of user!DEFAULT]
   * @param newPassword [DEFAULT!New password of user that  is complex(contains letters and numbers) 
  and not equal to previously used password!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.changeUserPassword = function(login, oldPassword, newPassword, callbackHandler) {
    var parameterNames = ["login", "oldPassword", "newPassword"];
    var parameterValues = [login, oldPassword, newPassword];
    var soapMessage = buildSOAPMessage("changeUserPassword", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Returns list of all tenants registered in system!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantList = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getTenantList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenant [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.addTenant = function(tenant, callbackHandler) {
    var parameterNames = ["tenant"];
    var tenantJSON = JSON.stringify(tenant);
    var parameterValues = [tenantJSON];
    var soapMessage = buildSOAPMessage("addTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Updates basic tenant information!DEFAULT]
   * @param tenant [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.updateTenant = function(tenant, callbackHandler) {
    var parameterNames = ["tenant"];
    var tenantJSON = JSON.stringify(tenant);
    var parameterValues = [tenantJSON];
    var soapMessage = buildSOAPMessage("updateTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!Internal storage ID of tenant to remove!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.removeTenant = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("removeTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Returns list of users of certain tenant!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param fillStatistics [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getUsersListByTenant = function(tenantID, fillStatistics, callbackHandler) {
    var parameterNames = ["tenantID", "fillStatistics"];
    var parameterValues = [tenantID, fillStatistics];
    var soapMessage = buildSOAPMessage("getUsersListByTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Creates new user ({@link SecurityAgent}) and adds him to given tenant!DEFAULT]
   * @param user [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.createUser = function(user, tenantID, callbackHandler) {
    var parameterNames = ["user", "tenantID"];
    var userJSON = JSON.stringify(user);
    var parameterValues = [userJSON, tenantID];
    var soapMessage = buildSOAPMessage("createUser", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param user [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.updateUser = function(user, callbackHandler) {
    var parameterNames = ["user"];
    var userJSON = JSON.stringify(user);
    var parameterValues = [userJSON];
    var soapMessage = buildSOAPMessage("updateUser", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Return {@link SecurityAgent} object by it's storage ID. 
  Returned object doesn't contain password due to security reasons!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getUserByID = function(userID, callbackHandler) {
    var parameterNames = ["userID"];
    var parameterValues = [userID];
    var soapMessage = buildSOAPMessage("getUserByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantByID = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("getTenantByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Performs creation of tenant and it's full infrastructure including following steps 
  - creation of tenant record
  - giving access to subsystems and components
  - creation of default business user, admin and technical user
  - adding gatekeeper for tenant if necessary!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.createTenantByRequest = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("createTenantByRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param requests [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.createTenantsByRequestList = function(requests, callbackHandler) {
    var parameterNames = ["requests"];
    var requestsJSON = JSON.stringify(requests);
    var parameterValues = [requestsJSON];
    var soapMessage = buildSOAPMessage("createTenantsByRequestList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getSecuritySessions = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("getSecuritySessions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sessionID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getSecuritySessionActions = function(sessionID, callbackHandler) {
    var parameterNames = ["sessionID"];
    var parameterValues = [sessionID];
    var soapMessage = buildSOAPMessage("getSecuritySessionActions", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantGroups = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getTenantGroups", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!Filter object containing conditions limiting tenant search!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantsByFilter = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("getTenantsByFilter", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountManagerUserID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantsForAccountManager = function(accountManagerUserID, callbackHandler) {
    var parameterNames = ["accountManagerUserID"];
    var parameterValues = [accountManagerUserID];
    var soapMessage = buildSOAPMessage("getTenantsForAccountManager", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Adds tenant to be managed by account manager user!DEFAULT]
   * @param accountManagerUserID [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.addTenantToAccountManager = function(accountManagerUserID, tenantID, callbackHandler) {
    var parameterNames = ["accountManagerUserID", "tenantID"];
    var parameterValues = [accountManagerUserID, tenantID];
    var soapMessage = buildSOAPMessage("addTenantToAccountManager", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountManagerUserID [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.removeTenantFromAccountManager = function(accountManagerUserID, tenantID, callbackHandler) {
    var parameterNames = ["accountManagerUserID", "tenantID"];
    var parameterValues = [accountManagerUserID, tenantID];
    var soapMessage = buildSOAPMessage("removeTenantFromAccountManager", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Generates security ticket for temporary access of tech services user.
  If user is not exists than creates this type of user for tenant.!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.generateTechServiceUserTicket = function(tenantID, businessServiceID, callbackHandler) {
    var parameterNames = ["tenantID", "businessServiceID"];
    var parameterValues = [tenantID, businessServiceID];
    var soapMessage = buildSOAPMessage("generateTechServiceUserTicket", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Returns list of all tenants available to given security agent!DEFAULT]
   * @param securityAgentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getAvailableTenants = function(securityAgentID, callbackHandler) {
    var parameterNames = ["securityAgentID"];
    var parameterValues = [securityAgentID];
    var soapMessage = buildSOAPMessage("getAvailableTenants", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accessRequest [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.createTenantForAccessRequest = function(accessRequest, callbackHandler) {
    var parameterNames = ["accessRequest"];
    var accessRequestJSON = JSON.stringify(accessRequest);
    var parameterValues = [accessRequestJSON];
    var soapMessage = buildSOAPMessage("createTenantForAccessRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Generates certain number of new {@link CredentialsBillet} for future use in registration procedure!DEFAULT]
   * @param billetsCount [DEFAULT!Number of billets to generate!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.generateCredentialsBillets = function(billetsCount, callbackHandler) {
    var parameterNames = ["billetsCount"];
    var parameterValues = [billetsCount];
    var soapMessage = buildSOAPMessage("generateCredentialsBillets", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param billetID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getCredentialBilletByID = function(billetID, callbackHandler) {
    var parameterNames = ["billetID"];
    var parameterValues = [billetID];
    var soapMessage = buildSOAPMessage("getCredentialBilletByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Updates tenant default business user login and password  by given credential billet!DEFAULT]
   * @param tenantExternalUID [DEFAULT!!DEFAULT]
   * @param billetID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.updateDefaultUserByCredentialBillet = function(tenantExternalUID, billetID, callbackHandler) {
    var parameterNames = ["tenantExternalUID", "billetID"];
    var parameterValues = [tenantExternalUID, billetID];
    var soapMessage = buildSOAPMessage("updateDefaultUserByCredentialBillet", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Returns list f tenants that have business application component 
  with given system name turned on!DEFAULT]
   * @param componentSystemName [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTenantsHavingComponent = function(componentSystemName, callbackHandler) {
    var parameterNames = ["componentSystemName"];
    var parameterValues = [componentSystemName];
    var soapMessage = buildSOAPMessage("getTenantsHavingComponent", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getEnabledAuthenticationServiceTypes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getEnabledAuthenticationServiceTypes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalToken [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.registerExternalUserToken = function(externalToken, callbackHandler) {
    var parameterNames = ["externalToken"];
    var externalTokenJSON = JSON.stringify(externalToken);
    var parameterValues = [externalTokenJSON];
    var soapMessage = buildSOAPMessage("registerExternalUserToken", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Pushed event of synchronization of tenant legal data stored in platform with application data!DEFAULT]
   * @param legalData [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.synchronizeTenantLegalData = function(legalData, callbackHandler) {
    var parameterNames = ["legalData"];
    var legalDataJSON = JSON.stringify(legalData);
    var parameterValues = [legalDataJSON];
    var soapMessage = buildSOAPMessage("synchronizeTenantLegalData", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param contactPerson [DEFAULT!!DEFAULT]
   * @param contactEmail [DEFAULT!!DEFAULT]
   * @param contactPhoneNumber [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.validateContacts = function(contactPerson, contactEmail, contactPhoneNumber, callbackHandler) {
    var parameterNames = ["contactPerson", "contactEmail", "contactPhoneNumber"];
    var parameterValues = [contactPerson, contactEmail, contactPhoneNumber];
    var soapMessage = buildSOAPMessage("validateContacts", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.generateOneTimePassword = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("generateOneTimePassword", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param variableName [DEFAULT!!DEFAULT]
   * @param variableValue [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.saveSessionVariable = function(variableName, variableValue, callbackHandler) {
    var parameterNames = ["variableName", "variableValue"];
    var parameterValues = [variableName, variableValue];
    var soapMessage = buildSOAPMessage("saveSessionVariable", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getSessionVariables = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getSessionVariables", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param targetTenantID [DEFAULT!!DEFAULT]
   * @param nonce [DEFAULT!!DEFAULT]
   * @param signature [DEFAULT!!DEFAULT]
   * @param domain [DEFAULT!!DEFAULT]
   * @param securityAgentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.createCrossTenantSecuritySession = function(targetTenantID, nonce, signature, domain, securityAgentID, callbackHandler) {
    var parameterNames = ["targetTenantID", "nonce", "signature", "domain", "securityAgentID"];
    var parameterValues = [targetTenantID, nonce, signature, domain, securityAgentID];
    var soapMessage = buildSOAPMessage("createCrossTenantSecuritySession", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ticketUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getSecurityTicket = function(ticketUID, callbackHandler) {
    var parameterNames = ["ticketUID"];
    var parameterValues = [ticketUID];
    var soapMessage = buildSOAPMessage("getSecurityTicket", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param domain [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getTicketUIDByUserID = function(userID, businessServiceID, domain, callbackHandler) {
    var parameterNames = ["userID", "businessServiceID", "domain"];
    var parameterValues = [userID, businessServiceID, domain];
    var soapMessage = buildSOAPMessage("getTicketUIDByUserID", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sendToEmail [DEFAULT!!DEFAULT]
   * @param templateFileName [DEFAULT!!DEFAULT]
   * @param parameters [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.sendEmailNotificationByTemplate = function(sendToEmail, templateFileName, parameters, callbackHandler) {
    var parameterNames = ["sendToEmail", "templateFileName", "parameters"];
    var parametersJSON = JSON.stringify(parameters);
    var parameterValues = [sendToEmail, templateFileName, parametersJSON];
    var soapMessage = buildSOAPMessage("sendEmailNotificationByTemplate", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param lead [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.addLead = function(lead, callbackHandler) {
    var parameterNames = ["lead"];
    var leadJSON = JSON.stringify(lead);
    var parameterValues = [leadJSON];
    var soapMessage = buildSOAPMessage("addLead", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param dateStart [DEFAULT!!DEFAULT]
   * @param dateFinish [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getLeads = function(dateStart, dateFinish, callbackHandler) {
    var parameterNames = ["dateStart", "dateFinish"];
    var parameterValues = [dateStart, dateFinish];
    var soapMessage = buildSOAPMessage("getLeads", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param emailAddress [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.checkLeadEmail = function(emailAddress, callbackHandler) {
    var parameterNames = ["emailAddress"];
    var parameterValues = [emailAddress];
    var soapMessage = buildSOAPMessage("checkLeadEmail", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param lead [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.updateLead = function(lead, callbackHandler) {
    var parameterNames = ["lead"];
    var leadJSON = JSON.stringify(lead);
    var parameterValues = [leadJSON];
    var soapMessage = buildSOAPMessage("updateLead", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param email [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getLeadByEmail = function(email, callbackHandler) {
    var parameterNames = ["email"];
    var parameterValues = [email];
    var soapMessage = buildSOAPMessage("getLeadByEmail", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param dateFrom [DEFAULT!!DEFAULT]
   * @param dateTo [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getAccessRequestList = function(dateFrom, dateTo, callbackHandler) {
    var parameterNames = ["dateFrom", "dateTo"];
    var parameterValues = [dateFrom, dateTo];
    var soapMessage = buildSOAPMessage("getAccessRequestList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getPartnerStatus = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getPartnerStatus", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.isServiceAvailable = function(businessServiceID, callbackHandler) {
    var parameterNames = ["businessServiceID"];
    var parameterValues = [businessServiceID];
    var soapMessage = buildSOAPMessage("isServiceAvailable", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tenantID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.getNoneBlockedUserByTenantID = function(tenantID, callbackHandler) {
    var parameterNames = ["tenantID"];
    var parameterValues = [tenantID];
    var soapMessage = buildSOAPMessage("getNoneBlockedUserByTenantID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tariffCode [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.addTariffRolesToUser = function(tariffCode, userID, callbackHandler) {
    var parameterNames = ["tariffCode", "userID"];
    var parameterValues = [tariffCode, userID];
    var soapMessage = buildSOAPMessage("addTariffRolesToUser", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sessionVariables [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.DirectoryServiceClient.prototype.saveSessionVariables = function(sessionVariables, callbackHandler) {
    var parameterNames = ["sessionVariables"];
    var sessionVariablesJSON = JSON.stringify(sessionVariables);
    var parameterValues = [sessionVariablesJSON];
    var soapMessage = buildSOAPMessage("saveSessionVariables", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Client API to ExternalSystemServiceWS web service
   */
  w.ExternalSystemServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "ExternalSystemServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param account [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.addExternalSystemAccount = function(account, callbackHandler) {
    var parameterNames = ["account"];
    var accountJSON = JSON.stringify(account);
    var parameterValues = [accountJSON];
    var soapMessage = buildSOAPMessage("addExternalSystemAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param account [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.updateExternalSystemAccount = function(account, callbackHandler) {
    var parameterNames = ["account"];
    var accountJSON = JSON.stringify(account);
    var parameterValues = [accountJSON];
    var soapMessage = buildSOAPMessage("updateExternalSystemAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.removeExternalSystemAccount = function(accountID, callbackHandler) {
    var parameterNames = ["accountID"];
    var parameterValues = [accountID];
    var soapMessage = buildSOAPMessage("removeExternalSystemAccount", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accountID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalSystemAccountByID = function(accountID, callbackHandler) {
    var parameterNames = ["accountID"];
    var parameterValues = [accountID];
    var soapMessage = buildSOAPMessage("getExternalSystemAccountByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalSystemAccounts = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getExternalSystemAccounts", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalSubsystemInstances = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getExternalSubsystemInstances", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalAccountID [DEFAULT!!DEFAULT]
   * @param accessToken [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.authorizeExternalAccount = function(externalAccountID, accessToken, callbackHandler) {
    var parameterNames = ["externalAccountID", "accessToken"];
    var accessTokenJSON = JSON.stringify(accessToken);
    var parameterValues = [externalAccountID, accessTokenJSON];
    var soapMessage = buildSOAPMessage("authorizeExternalAccount", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ssiGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalAccountsBySSI = function(ssiGUID, callbackHandler) {
    var parameterNames = ["ssiGUID"];
    var parameterValues = [ssiGUID];
    var soapMessage = buildSOAPMessage("getExternalAccountsBySSI", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalSystemAccount [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.refreshTokenForAccount = function(externalSystemAccount, callbackHandler) {
    var parameterNames = ["externalSystemAccount"];
    var externalSystemAccountJSON = JSON.stringify(externalSystemAccount);
    var parameterValues = [externalSystemAccountJSON];
    var soapMessage = buildSOAPMessage("refreshTokenForAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param excludedIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalSystemAccountsExcludedIDs = function(excludedIDs, callbackHandler) {
    var parameterNames = ["excludedIDs"];
    var excludedIDsJSON = JSON.stringify(excludedIDs);
    var parameterValues = [excludedIDsJSON];
    var soapMessage = buildSOAPMessage("getExternalSystemAccountsExcludedIDs", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalSystemAccountUID [DEFAULT!!DEFAULT]
   * @param ssiGUID [DEFAULT!!DEFAULT]
   * @param tenantProviderID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getTenantByExternalSystemAccountUID = function(externalSystemAccountUID, ssiGUID, tenantProviderID, callbackHandler) {
    var parameterNames = ["externalSystemAccountUID", "ssiGUID", "tenantProviderID"];
    var parameterValues = [externalSystemAccountUID, ssiGUID, tenantProviderID];
    var soapMessage = buildSOAPMessage("getTenantByExternalSystemAccountUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ssiGUID [DEFAULT!!DEFAULT]
   * @param esaGUID [DEFAULT!!DEFAULT]
   * @param businessServiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getExternalSystemAccountByGUID = function(ssiGUID, esaGUID, businessServiceID, callbackHandler) {
    var parameterNames = ["ssiGUID", "esaGUID", "businessServiceID"];
    var parameterValues = [ssiGUID, esaGUID, businessServiceID];
    var soapMessage = buildSOAPMessage("getExternalSystemAccountByGUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ssInstanceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.createAuthorizationRequest = function(ssInstanceID, callbackHandler) {
    var parameterNames = ["ssInstanceID"];
    var parameterValues = [ssInstanceID];
    var soapMessage = buildSOAPMessage("createAuthorizationRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.updateAuthorizationRequest = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("updateAuthorizationRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param requestUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getAuthorizationRequestByUID = function(requestUID, callbackHandler) {
    var parameterNames = ["requestUID"];
    var parameterValues = [requestUID];
    var soapMessage = buildSOAPMessage("getAuthorizationRequestByUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param answer [DEFAULT!!DEFAULT]
   * @param authRequestID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.storeAccessConsent = function(answer, authRequestID, callbackHandler) {
    var parameterNames = ["answer", "authRequestID"];
    var parameterValues = [answer, authRequestID];
    var soapMessage = buildSOAPMessage("storeAccessConsent", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param subsystemUID [DEFAULT!!DEFAULT]
   * @param clientID [DEFAULT!!DEFAULT]
   * @param accessToken [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.generateClientSecret = function(subsystemUID, clientID, accessToken, callbackHandler) {
    var parameterNames = ["subsystemUID", "clientID", "accessToken"];
    var parameterValues = [subsystemUID, clientID, accessToken];
    var soapMessage = buildSOAPMessage("generateClientSecret", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Возвращает список TenantID для SubsystemIDs!DEFAULT]
   * @param ssiGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.getTenantIDsBySSID = function(ssiGUID, callbackHandler) {
    var parameterNames = ["ssiGUID"];
    var parameterValues = [ssiGUID];
    var soapMessage = buildSOAPMessage("getTenantIDsBySSID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Возвращает true, если у тенанта есть хотя бы один валидный внешний аккаунт для subsystem  instance guid!DEFAULT]
   * @param ssiGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.ExternalSystemServiceClient.prototype.hasValidExternalAccountBySSI = function(ssiGUID, callbackHandler) {
    var parameterNames = ["ssiGUID"];
    var parameterValues = [ssiGUID];
    var soapMessage = buildSOAPMessage("hasValidExternalAccountBySSI", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Client API to GatekeeperServiceWS web service
   */
  w.GatekeeperServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "GatekeeperServiceWSImpl";
  };
  
  /***
   * [DEFAULT!Authenticates call from external tenant using gatekeeper.
   Call must be performed under gateeeper credentials that has rights to authorize given tenant!DEFAULT]
   * @param token [DEFAULT!Token containing gatekeepers credentials and UID of tenant to authenticate!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.GatekeeperServiceClient.prototype.authenticateExternalTenant = function(token, callbackHandler) {
    var parameterNames = ["token"];
    var tokenJSON = JSON.stringify(token);
    var parameterValues = [tokenJSON];
    var soapMessage = buildSOAPMessage("authenticateExternalTenant", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Checks whether tenant with given token is available to current gatekeeper!DEFAULT]
   * @param token [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.GatekeeperServiceClient.prototype.checkExternalTenantAvailable = function(token, callbackHandler) {
    var parameterNames = ["token"];
    var tokenJSON = JSON.stringify(token);
    var parameterValues = [tokenJSON];
    var soapMessage = buildSOAPMessage("checkExternalTenantAvailable", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param externalSystemAccountGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.GatekeeperServiceClient.prototype.authenticateByExternalSystemAccount = function(externalSystemAccountGUID, callbackHandler) {
    var parameterNames = ["externalSystemAccountGUID"];
    var parameterValues = [externalSystemAccountGUID];
    var soapMessage = buildSOAPMessage("authenticateByExternalSystemAccount", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * Client API to RegistrationServiceWS web service
   */
  w.RegistrationServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "RegistrationServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param accessRequest [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.registerAccessRequest = function(accessRequest, callbackHandler) {
    var parameterNames = ["accessRequest"];
    var accessRequestJSON = JSON.stringify(accessRequest);
    var parameterValues = [accessRequestJSON];
    var soapMessage = buildSOAPMessage("registerAccessRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Activates previously submitted request by accepting activation code from user.!DEFAULT]
   * @param activationCode [DEFAULT!Activation code that was sent to the user as a response to access request!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.activateRequest = function(activationCode, callbackHandler) {
    var parameterNames = ["activationCode"];
    var parameterValues = [activationCode];
    var soapMessage = buildSOAPMessage("activateRequest", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param code [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.validatePromoCode = function(code, callbackHandler) {
    var parameterNames = ["code"];
    var parameterValues = [code];
    var soapMessage = buildSOAPMessage("validatePromoCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Validates login for new tenant registration. If login could be used - returns true, otherwise, if login is already in use returns false!DEFAULT]
   * @param login [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.validateLogin = function(login, callbackHandler) {
    var parameterNames = ["login"];
    var parameterValues = [login];
    var soapMessage = buildSOAPMessage("validateLogin", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.registerLeadTransition = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("registerLeadTransition", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param login [DEFAULT!!DEFAULT]
   * @param ticketUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.otpRequestAuthCode = function(login, ticketUID, callbackHandler) {
    var parameterNames = ["login", "ticketUID"];
    var parameterValues = [login, ticketUID];
    var soapMessage = buildSOAPMessage("otpRequestAuthCode", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param authCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.otpSendConfirmation = function(authCode, callbackHandler) {
    var parameterNames = ["authCode"];
    var parameterValues = [authCode];
    var soapMessage = buildSOAPMessage("otpSendConfirmation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param otp [DEFAULT!!DEFAULT]
   * @param authCode [DEFAULT!!DEFAULT]
   * @param userID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.otpCheckConfirmation = function(otp, authCode, userID, callbackHandler) {
    var parameterNames = ["otp", "authCode", "userID"];
    var parameterValues = [otp, authCode, userID];
    var soapMessage = buildSOAPMessage("otpCheckConfirmation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param login [DEFAULT!!DEFAULT]
   * @param businessServiceCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.RegistrationServiceClient.prototype.validateLoginForBusinessService = function(login, businessServiceCode, callbackHandler) {
    var parameterNames = ["login", "businessServiceCode"];
    var parameterValues = [login, businessServiceCode];
    var soapMessage = buildSOAPMessage("validateLoginForBusinessService", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'atr-framework-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.SecuritySecurityRights = {
  
      SECURITY_AGENT_ADMINISTRATION_RIGHT:new SecurityRightCheckHandle('c9c556aa-cd9d-453e-b7e3-d615c5c60de6'),
      TENANT_ADMINISTRATION_RIGHT:new SecurityRightCheckHandle('6f354adb-6539-4e9a-a35d-f67746be1638'),
      X_SECURITY__CHANGE_USERS:new SecurityRightCheckHandle('4a6f14cb-9ae5-42eb-96d3-c42b564361c9')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  