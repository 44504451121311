import { configurationApi } from '@core/api/configuration';
import { coreApi } from '@core/api/core';
import { getTicketUid } from '@core/utils/storage';
import { fromStringToBoolean } from '@utils/serializer';
import * as securitypackage from 'securitypackage-api';

export type ChangeUserRolesOptions = {
	addIDs: Array<number>;
	removeIDs: Array<number>;
	userID: number;
};

const securityApi = {
	package: securitypackage as typeof securitypackageApi,
	authentication: {
		client: new securitypackage.AuthenticationServiceClient(),
		fetchAgentByTicket: () => {
			return new Promise<SecurityAgent>(resolve => {
				const ticketUid = getTicketUid();

				securityApi.authentication.client.getAgentByTicket(ticketUid, result => {
					resolve(result);
				});
			});
		},
		fetchCarrotQuestAuthHash: (userId: number) => {
			return new Promise<string>(resolve => {
				securityApi.authentication.client.generateCarrotQuestAuthHash(userId, result => {
					resolve(result);
				});
			});
		},
	},
	authorization: {
		client: new securitypackage.AuthorizationServiceClient() as AuthorizationServiceClient,
		changeUserRights: (userID: number, rights: Array<SecurityRight>) => {
			type SyntheticRight = SecurityRight & { LocalID: number };

			return new Promise<boolean>(resolve => {
				const transformedRights: Array<CodeNaturalKey> = rights.map((x: SyntheticRight) => {
					return {
						...new coreApi.package.CodeNaturalKey(),
						ID: x.LocalID,
						Code: x.SystemName,
						Name: x.Name,
					};
				});

				securityApi.authorization.client.changeUserRights(userID, transformedRights, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		fetchAvailableRights: () => {
			return new Promise<Array<SecurityRight>>(resolve => {
				securityApi.authorization.client.getAvailableRights(result => {
					resolve(result);
				});
			});
		},
	},
	directory: {
		client: new securitypackage.DirectoryServiceClient() as DirectoryServiceClient,
		addUser: (user: SecurityAgent, tenantId: number) => {
			const rights = user.SecurityRights;

			return new Promise<SecurityAgent>(resolve => {
				securityApi.directory.client.createUser(user, tenantId, async result => {
					const userID = result.StorageID;

					await securityApi.authorization.changeUserRights(userID, rights);
					const newUser = await securityApi.directory.fetchUserByID(userID);

					resolve(newUser);
				});
			});
		},
		fetchSessionVariables: () => {
			return new Promise<Array<SessionVariable>>(resolve => {
				securityApi.directory.client.getSessionVariables(result => {
					resolve(result);
				});
			});
		},
		fetchUserByID: (ID: number) => {
			return new Promise<SecurityAgent>(resolve => {
				securityApi.directory.client.getUserByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchUserList: (tenantID: number) => {
			return new Promise<Array<SecurityAgent>>(resolve => {
				securityApi.directory.client.getUsersListByTenant(tenantID, false, result => {
					resolve(result);
				});
			});
		},
		fetchTenantByID: (ID: number) => {
			return new Promise<Tenant>(resolve => {
				securityApi.directory.client.getTenantByID(ID, result => {
					resolve(result);
				});
			});
		},
		updateUser: (user: SecurityAgent) => {
			const userID = user.StorageID;
			const rights = user.SecurityRights;

			return new Promise<SecurityAgent>(resolve => {
				securityApi.directory.client.updateUser(user, async () => {
					await securityApi.authorization.changeUserRights(userID, rights);
					const newUser = await securityApi.directory.fetchUserByID(userID);

					resolve(newUser);
				});
			});
		},
		validateContacts: (options: ValidateContactsOptios) => {
			const { name, email, phone } = options;

			return new Promise<boolean>(resolve => {
				securityApi.directory.client.validateContacts(name, email, phone, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
	externalSystem: {
		client: new securitypackage.ExternalSystemServiceClient() as ExternalSystemServiceClient,
		fetchExternalSystemAccountByID: (ID: number) => {
			return new Promise<ExternalSystemAccount>(resolve => {
				securityApi.externalSystem.client.getExternalSystemAccountByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchExternalSubsystemInstances() {
			return new Promise<Array<SubsystemInstance>>(resolve => {
				securityApi.externalSystem.client.getExternalSubsystemInstances(result => {
					resolve(result);
				});
			});
		},
		fetchIntegrationsData() {
			return new Promise<FetchIntegrationsDataReturnType>(async resolve => {
				const remoteExternalServicesMap: Record<string, RemoteExternalService> = {};
				const subSystemInstances = await securityApi.externalSystem.fetchExternalSubsystemInstances();
				const asyncQueue = subSystemInstances.map(ssInstance => {
					return new Promise<boolean>(resolve => {
						configurationApi.subsystem.fetchRemoteExternalServiceBySubsystemID(ssInstance.ID).then(result => {
							remoteExternalServicesMap[ssInstance.ID] = result;
							resolve(true);
						});
					});
				});

				Promise.all(asyncQueue).then(() => {
					resolve({
						subSystemInstances,
						remoteExternalServicesMap,
					});
				});
			});
		},
		fetchExternalSystemAccounts: () => {
			return new Promise<Array<ExternalSystemAccount>>(resolve => {
				securityApi.externalSystem.client.getExternalSystemAccounts(result => {
					resolve(result);
				});
			});
		},
		addExternalSystemAccount: (account: ExternalSystemAccount) => {
			return new Promise<ExternalSystemAccount>(resolve => {
				securityApi.externalSystem.client.addExternalSystemAccount(account, result => {
					resolve(result);
				});
			});
		},
		updateExternalSystemAccount(account: ExternalSystemAccount) {
			return new Promise<ExternalSystemAccount>(resolve => {
				securityApi.externalSystem.client.updateExternalSystemAccount(account, result => {
					resolve(result);
				});
			});
		},
		removeExternalSystemAccount(ID: number) {
			return new Promise<boolean>(resolve => {
				securityApi.externalSystem.client.removeExternalSystemAccount(ID, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
	registration: {
		client: new securitypackage.RegistrationServiceClient() as RegistrationServiceClient,
		validateLogin: (login: string) => {
			return new Promise<boolean>(resolve => {
				securityApi.registration.client.validateLogin(login, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
	},
};

export type FetchIntegrationsDataReturnType = {
	subSystemInstances: Array<SubsystemInstance>;
	remoteExternalServicesMap: Record<string, RemoteExternalService>;
};

export type ValidateContactsOptios = {
	name: string;
	email: string;
	phone: string;
};

export { securityApi };
