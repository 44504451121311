export enum VirtualListGroupCode {
	TENANT_ENTITY = 'TENANT_ENTITY',
	PURPOSE = 'PURPOSE',
	CURRENCY = 'CURRENCY',
}

export enum DrawerZone {
	LIST = 'LIST',
	ACOUNTS = 'ACOUNTS',
	SIGNUP = 'SIGNUP',
	IMPORT_CMS = 'IMPORT_CMS',
	IMPORT_CMS_PROGRESS = 'IMPORT_CMS_PROGRESS',
	IMPORT_CMS_RESULT = 'IMPORT_CMS_RESULT',
	REFRESH_CMS = 'REFRESH_CMS',
	REFRESH_CMS_PROGRESS = 'REFRESH_CMS_PROGRESS',
	REFRESH_CMS_RESULT = 'REFRESH_CMS_RESULT',
	IMPORT_FILE = 'IMPORT_FILE',
	IMPORT_FILE_PROGRESS = 'IMPORT_FILE_PROGRESS',
	IMPORT_FILE_RESULT = 'IMPORT_FILE_RESULT',
}

export enum ServerStatus {
	WAITING = 'WAITING',
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	FINISHED_WITH_WARNING = 'FINISHED_WITH_WARNING',
	FAILED = 'FAILED',
	DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',
}

export enum FundsRegisterPurposeCode {
	ACCOUNTING = 'ACCOUNTING',
	CARD = 'CARD',
	CREDIT = 'CREDIT',
	DEPOSIT = 'DEPOSIT',
}

export enum CMSDataType {
	LEGAL_ENTITY_BANK_ACCOUNT = 'LEGAL_ENTITY_BANK_ACCOUNT',
	LEGAL_ENTITY_BANK_TRANSACTION = 'LEGAL_ENTITY_BANK_TRANSACTION',
	MARKETPLACE_TRANSACTION = 'MARKETPLACE_TRANSACTION',
	NATURAL_PERSON_CARD_ACCOUNT = 'NATURAL_PERSON_CARD_ACCOUNT',
	NATURAL_PERSON_CARD_TRANSACTION = 'NATURAL_PERSON_CARD_TRANSACTION',
	POS_TERMINAL = 'POS_TERMINAL',
	POS_TERMINAL_TRANSACTION = 'POS_TERMINAL_TRANSACTION',
}

export enum SettingsKey {
	IS_BALANCE_HIDDEN = 'is_balance_hidden',
}

export type LevelAccess = 'read' | 'write';

export type DirectionAccess = 'all' | 'receipt' | 'charge';

export enum SyncMarkerInitiators {
	LIST = 'LIST',
	ACCOUNT_MENU = 'ACCOUNT_MENU',
}
